@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.wrapper {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F7;

    & .container {
        width: 70%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 auto;

        @media screen and (min-width: 1921px) {
            max-width: 1740px;
        }

        @include tablet {
            flex-direction: column-reverse;
            gap: 100px;
            align-items: start;
            width: 90%;
        }

        & .contact_block {
            flex: 0 1 50%;

            @include tablet {
                margin-bottom: 50px;
                flex: 0 1 100%;
                width: 100%;
            }

            & .contacts {
                margin-bottom: 80px;
            }

            & .contacts,
            & .address {
                & h2 {
                    @include playfair400;
                    font-size: 40px;
                    line-height: 56px;
                    margin-bottom: 30px;
                    color: $font_color;

                    @include mobileL {
                        font-size: 36px;
                    }
                }

                & p {
                    @include lato400;
                    font-size: 20px;
                    color: $font_color;
                    margin-bottom: 10px;
                    display: flex;
                    gap: 12px;

                    @include mobileL {
                        font-size: 16px;
                    }

                    a {
                        color: inherit;
                        font: inherit;
                        text-decoration: underline;
                    }
                }
            }
        }

        & .callback_block {
            flex: 0 1 40%;

            @include tablet {
                flex: 0 1 100%;
                margin-top: 64px;
                width: 100%;
            }

            & h2 {
                @include playfair400;
                font-size: 40px;
                color: $font_color;
                margin-bottom: 32px;

                @include mobileL {
                    font-size: 36px;
                }
            }
        }
    }
}