@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.links {
    background-color: #FAF9F7;
    padding: 30px 0;

    & .inner_links_container {
        display: flex;
        align-items: center;
        gap: 20px;
        font-size: 16px;
        user-select: none;

        & a {
            @include lato400;
            color: #22222270;
        }

        & .catalog_name {
            color: $font_color;
            @include lato700;

            &:first-letter {
                text-transform: uppercase;
            }
        }
    }
}

.title {
    text-align: center;
    padding: 37px 0;
    border-bottom: 1px solid #22222240;
    margin-bottom: 64px;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 22px;
    margin-bottom: 64px;

    & .empty {
        font-size: 20px;
        @include playfair400;
        color: $font_color;
    }

    @media(max-width:960px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width:768px) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.pagination_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error {
    font-size: 20px;
    @include playfair400;
    color: $font_color;
    text-align: center;
    margin: 100px 0;
}