@import './../../SCSS/vars';
@import './../../SCSS/mixins';


.wrapper {
    display: flex;
    justify-content: space-between;
    padding: 100px 0;

    @include tablet {
        flex-direction: column;
        align-items: center;
        gap: 65px;
        padding: 50px 0;

    }

    & .content_title {
        flex: 0 1 45%;

        @include tablet {
            flex: 0 1 100%;

        }

        & .in_stock {
            @include lato400;
            color: $font_gold;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 5px;
        }

        & .title {
            font-size: 70px;
            margin: 40px 0;

            @include tablet {
                font-size: 36px;
                text-align: left;
                margin: 20px 0;

            }
        }

        & .price {
            @include playfair400;
            font-size: 48px;
            line-height: 56px;
            color: $font_color;
            margin-bottom: 60px;

            @include tablet {
                font-size: 36px;
            }
        }

        & .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 25px;
            margin-bottom: 60px;


            & .button {
                background: $font_color;
                color: #FFF;
                flex: 1;

                @media(max-width:480px) {
                    padding: 20px 10px;
                }

            }

            & button {
                width: 56px;
                height: 56px;
                border-radius: 50%;
                background: transparent;
                border: 1.5px solid $font_color;
                display: flex;
                justify-content: center;
                align-items: center;

                & svg {
                    fill: #FFF;
                    width: 23.34px;
                    height: 21.01px;

                    & path {
                        stroke: $font_color;
                    }
                }
            }
        }
    }
}