@import './../../SCSS/vars';
@import './../../SCSS/mixins';

// .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 65px;

    @media (max-width:960px) {
        justify-content: center;

    }

    & .customs {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        @media (max-width:960px) {
            display: none;
        }

        & .customPagination {
            font-size: 20px;
            line-height: 30px;
            color: $font_color;
            @include lato700;
            position: relative;
            bottom: 0;
            z-index: 1;

        }

        & .customButtonPrev {
            transform: rotate(180deg);
        }

        & .customButtonNext,
        .customButtonPrev {
            background: transparent;
            width: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;

            & svg {
                width: 20px;

            }
        }

    }
}

.box {

    & .card2,
    .card {
        width: 20%;
        height: 500px;
        background: rgba(0, 123, 255, 0.218);
    }

    & .card2 {
        background: rgba(255, 0, 208, 0.218);
    }

    & .sliders {
        display: flex;
        justify-content: space-between;

        & div {
            flex: 0 1 99.5%;

        }
    }
}