@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';

// .swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled
.wrapper {
    & .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & h2 {
            margin-bottom: 65px;
        }

        & .customs {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;

            & .customPagination {
                font-size: 20px;
                line-height: 30px;
                color: $font_color;
                @include lato700;
                position: relative;
                bottom: 0;
            }

            & .customButtonPrev {
                transform: rotate(180deg);
            }

            & .customButtonNext,
            .customButtonPrev {
                background: transparent;
                width: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;

                & svg {
                    width: 20px;

                }
            }

        }
    }

    & .box {

        & .card2,
        .card {
            width: 20%;
            height: 500px;
            background: rgba(0, 123, 255, 0.218);
        }

        & .card2 {
            background: rgba(255, 0, 208, 0.218);
        }

        & .sliders {
            display: flex;
            justify-content: space-between;
        }
    }
}