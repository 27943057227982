@import '../../SCSS/vars';
@import '../../SCSS/mixins';

.form {
    display: flex;
    flex-direction: column;
    gap: 25px;

    & .boxReCaptcha {
        & iframe {
            & #rc-imageselect {
                background: red;
            }
        }
    }

    & .button {
        margin-top: 20px;
        background: $font_color;
        color: #FFF;

        &:hover {
            background: $font_color;
            color: #FFF;

        }


    }

    & .inputField {
        background: #FFF;
        transition: .3s;

        & label {
            background: transparent;
            color: $font_color_trans;
        }

        & input {
            border: 1px solid #EFEEEB;

            &:focus {
                border-bottom: 1px solid $font_gold;

            }
        }

        & fieldset {
            border: none
        }
    }
}