@import './../../SCSS/mixins';
@import './../../SCSS/vars';


.container {
    width: 100%;

    @media (min-width:960px) {
        display: none;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        & .logo {
            display: flex;
            flex-direction: column;


            & img {
                width: 90px;
                margin-bottom: 6px;
            }

            & p {
                font-size: 16px;
                color: $font_gold ;
                @include lato500;
                line-height: 14px;
                letter-spacing: 1px
            }

        }

        & .button {
            width: 50px;
            background: transparent;

            & .ham {
                cursor: pointer;
                -webkit-tap-highlight-color: transparent;
                transition: transform 400ms;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                user-select: none;
                transform: scaleY(0.8) scaleX(1.2);
            }

            & .burger6.isClosed.hamRotate {
                transform: rotate(45deg) scale(1.2);
            }

            & .line {
                fill: none;
                transition: 0.4s;
                stroke: #000000;
                stroke-width: 3;
                stroke-linecap: round;
            }

            & .ham1 .top {
                stroke-dasharray: 40 139;
            }

            & .ham1 .bottom {
                stroke-dasharray: 40 180;
            }

            & .burger6.isClosed.ham1 .top {
                stroke-dashoffset: -98px;
            }

            & .burger6.isClosed.ham1 .bottom {
                stroke-dashoffset: -138px;
            }

        }
    }

    & .wrapper {
        background: #ffffff;
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 10;
        top: 97px;
        left: 100%;
        border-top: 1px solid #22222240;
        transition: 0.3s ease;

        & nav {
            display: block;
            width: 85%;
            margin: 0 auto;
            padding: 24px 0;

            & ul {
                display: flex;
                flex-direction: column;
                gap: 30px;

                & .anotherStyle {
                    color: #117451;
                    text-align: center;
                    margin-right: 40px;

                    &.active {
                        color: #117451;

                    }

                    &:hover {
                        color: #117451;
                    }
                }

                & a {
                    @include lato700;
                    font-size: 16px;
                    line-height: 24px;
                    color: $font_color;
                    position: relative;
                }

                .whish::before {
                    content: attr(data-count);
                    position: absolute;
                    top: 50%;
                    right: -27px;
                    transform: translate(-0%, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    @include lato400;
                    font-size: 12px;
                    border-radius: 50%;
                    background-color: $font_gold;
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }

    & .active {
        left: 0;
    }





}