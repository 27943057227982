.box {

    &>button {
        background-color: white;
        animation: boxAnimation 3s infinite;

        & .icon {
            width: 35px;
            height: 35px;

            & path {
                animation: boxAnimation 3s infinite;
            }
        }

        &:hover {
            background-color: white;
            animation: none;

            & .icon {
                & path {
                    animation: none;
                }
            }
        }
    }

    & a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}

@keyframes boxAnimation {

    0%,
    100% {
        background-color: white;
        fill: #30b74d;
    }

    50% {
        background-color: #30b74d;
        fill: white;
    }
}