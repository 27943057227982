@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';


.wrapper {
    padding: 100px 0;
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    @media(max-width:960px) {
        flex-direction: column;
        gap: 47px;
        padding: 100px 0 0;
    }

    & .content_img {
        display: flex;
        justify-content: center;
        flex: 0 1 50%;


        div {
            width: 60%;
            border-radius: 100%;
            border: 1px solid $font_gold ;

            @media(max-width:960px) {
                width: 80%;

            }


            & img {
                object-fit: cover;
                border-radius: 100%;
                width: 100%;
                height: 100%;
                transform: translate(-20px, -5px);

                @media(max-width:960px) {
                    transform: translate(-10px, -2px);

                }
            }
        }
    }

    & .content_title {
        flex: 0 1 50%;
        border-left: 1px solid #22222240;
        padding: 43px 0 43px 20px;

        @media(max-width:960px) {
            border: none;
            padding: 0px;

        }

        & div {
            padding-left: 10%;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 30px;

            @media(max-width:960px) {
                padding-left: 0%;
                gap: 20px;
            }


            & .button {
                background: rgba(17, 116, 81, 1);
                color: #FFF;

                & svg {
                    & path {
                        fill: #FFF;
                    }
                }

                @media(max-width:960px) {
                    width: 100%;
                }
            }

            & .button_insta {
                display: flex;
                align-items: center;
                gap: 10px;
                color: #000;
                @include lato400;
                font-size: 24px;
                margin-bottom: 20px;

                @media(max-width:960px) {
                    margin-bottom: 10px;
                }

                & svg {
                    width: 35px;
                    height: 35px;
                }
            }


            & article {
                width: 80%;
                @include lato400;
                color: $font_color_trans;
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 20px;

                @media(max-width:960px) {
                    font-size: 16px;
                    line-height: 24px;
                    width: 100%;
                    margin-bottom: 10px;

                }
            }
        }

    }
}