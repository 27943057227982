@import './../../SCSS/mixins';
@import './../../SCSS/vars';


// Header.mod.menue.scss

header {
    border-bottom: 1px solid #22222240;
    background-color: #FFF;

    @media(max-width:960px) {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 10;

    }
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 0;

    @media (max-width:960px) {
        padding: 22px 0;
    }

    & nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 0 1 54%;

        @media (max-width:1700px) {
            flex: 0 1 60%;

        }

        @media (max-width:960px) {
            display: none;
        }
    }

    & .menu {
        display: flex;
        align-items: center;
        gap: 30px;

        @media (max-width:1360px) {
            gap: 15px;
        }

        @media (max-width:960px) {
            display: none;
        }

        & a {
            @include lato400;
            font-size: 16px;
            line-height: 24px;
            color: $font_color;
            position: relative;
            transition: 0.3s;

            @media (max-width:1150px) {
                font-size: 14px;
            }

            &.active {
                color: $font_gold ;

                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: "";
                height: 1px;
                width: 0%;
                background: $font_gold;
                position: absolute;
                bottom: -5px;
                left: 0;
                transition: 0.3s;
            }

            &:hover {
                color: $font_gold;

                &::after {
                    width: 100%;
                }
            }
        }

        & .anotherStyle {
            color: rgba(17, 116, 81, 1);
            text-align: center;
            margin-right: 40px;

            &.active {
                color: rgba(17, 116, 81, 1);

            }

            &:hover {
                color: rgba(17, 116, 81, 1);
            }
        }

        .whish::before {
            content: attr(data-count);
            position: absolute;
            top: -15px;
            right: -15px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            @include lato400;
            font-size: 12px;
            border-radius: 50%;
            background-color: $font_gold;
            width: 20px;
            height: 20px;
        }

        & .sosial_icon {
            display: flex;
            align-items: center;
            gap: 20px;


            & li {
                transition: .3s;

                & a {
                    &::after {
                        content: '';
                        display: none;
                    }
                }

                &:hover {
                    transform: scale(1.2);


                }
            }
        }
    }

    & .logo {
        @include playfair400;
        font-size: 32px;
        color: $font_color;
        line-height: 24px;
        letter-spacing: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

        & img {
            width: 90px;
            margin-bottom: 14px;
        }

        & p {
            font-size: 16px;
            color: $font_gold ;
            @include lato500;
            line-height: 14px;
            letter-spacing: 1px
        }



        @media (max-width:1150px) {
            font-size: 25px;
        }
    }
}