@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';

.wrapper {

    & .inputField {
        width: 100%;
        background: #FFF;
        transition: .3s;
        margin-top: 16px;


        & label {
            background: transparent;
            color: $font_color_trans;
        }

        & input {
            border: 1px solid #EFEEEB;

            &:focus {
                border-bottom: 1px solid $font_gold;

            }
        }

        & fieldset {
            border: none
        }
    }

    & .table_box {
        margin-top: 80px;
        background: #FFF;

        & ul {
            display: flex;
            justify-content: space-between;
            align-items: stretch;

            @include tablet {
                flex-direction: column;
                align-items: flex-start;

            }

            & li:first-child {
                & div {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    max-width: 350px;

                    @include tablet {
                        flex-direction: column;
                        max-width: 250px;

                    }

                    & img {
                        width: 140px;
                        height: 140px;
                        object-fit: cover;
                    }

                }
            }

            & li {
                border-right: 2px solid #FAF9F7;
                display: flex;
                flex-direction: column;

                @include tablet {
                    flex-direction: row;
                    border-bottom: 2px solid #FAF9F7;
                    border-right: none;
                }

                & p {
                    @include lato400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                }

                & span {
                    @include lato400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                    color: $font_color_trans;
                    padding: 15px 30px;
                    border-bottom: 2px solid #FAF9F7;
                    display: block;

                    @include tablet {
                        text-align: start;
                        width: 200px;
                        border-right: 2px solid #FAF9F7;
                        border-bottom: none;
                    }

                    @include mobileL {
                        text-align: start;
                        width: 120px;
                        border-right: 2px solid #FAF9F7;
                        border-bottom: none;
                    }
                }



                & div {
                    padding: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;


                }

                .colorOption {
                    position: relative;

                    span {
                        position: absolute;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        padding: 0;
                        background: transparent;
                        border: 1px solid #838383;
                        transform: translateX(5px);
                        @include lato600;
                        font-size: 14px;
                        line-height: 24px;
                        color: $font_color;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:nth-child(6) {
                            background: #fff;
                            border: 2px solid #ccc;
                        }
                    }
                }


                & .sizeOption {
                    max-width: 120px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    @include tablet {
                        max-width: 100%;
                    }

                    & span {
                        padding: 0px;

                        @include tablet {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }

    & .buttonBack {
        display: block;
        margin: 0 auto;
        border: none;
        position: relative;
        padding: 0;

        &::after {
            content: '';
            width: 0%;
            height: 2px;
            position: absolute;
            background: $font_gold;
            bottom: -5px;
            left: 0;
            transition: .3s;
        }

        &:hover {
            &::after {
                width: 100%;
            }

            color: $font_color;
            background: transparent;
        }
    }

    & .button {
        background: $font_color;
        color: #FFF;
        margin-bottom: 16px;
        width: 100%;
    }

    & .text {
        margin: 8px 0 40px;
        @include lato400;
        font-size: 16px;
        line-height: 24px;
        color: $font_color;
    }
}