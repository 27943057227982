.swiper-button-disabled {
    opacity: 1;

    & svg {
        color: #22222240;
    }
}

.swiper-pagination-total {
    color: #22222240;
}