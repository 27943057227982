@import './../../SCSS/vars';
@import './../../SCSS/mixins';


.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    &:hover {
        & .box_image {
            .btn {
                visibility: visible;
                bottom: 50px;
                opacity: 1;

                &:hover {
                    padding-right: 30px;
                }
            }
        }

        & h4 {
            color: $font_gold;
        }
    }

    & .discounts {
        position: absolute;
        top: 25px;
        left: 25px;
        padding: 12px 24px;
        background: #B59A6C;
        color: #FFF;
        @include lato400;
        font-size: 16px;
        z-index: 5;
    }

    & .favorite {
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 25px;
        right: 25px;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
    }

    @media(max-width:960px) {
        align-items: start;

        .btn {
            display: none;
        }
    }



    & .box_image {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        img {
            height: 590px;
            width: 100%;
            object-fit: cover
        }


        & .btn {
            @include lato400;
            position: absolute;
            background: #FFF;
            color: $font_color;
            bottom: 0px;
            box-shadow: 0 5px 2px 0 #5959591A, 0 9px 9px 0 #59595917;
            line-height: 24px;
            letter-spacing: 5px;
            border: none;
            transition: 0.3s;
            visibility: hidden;
            opacity: 0;
        }

    }

    & h4 {
        @include lato600;
        font-size: 24px;
        line-height: 30px;
        color: $font_color;
        margin-bottom: 8px;
        transition: 0.3s;

        @media(max-width:960px) {
            font-size: 18px;
        }
    }

    & span {
        color: $font_color_trans;
        font-size: 20px;
        line-height: 30px;
        @include lato400;

        @media(max-width:960px) {
            font-size: 16px;
        }
    }
}