@import './../../SCSS/mixins';
@import './../../SCSS/vars';


.container {
    max-width: 75%;
    margin: 0 auto;
    padding: 100px 0 60px;

    @media (min-width: 1921px) {
        max-width: 1740px;
    }

    @media (max-width:1350px) {
        max-width: 85%;
    }

    @include mobileS {
        padding: 60px 0 60px;
    }


    & .box_copyright {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width:960px) {
            display: block;

            & p:nth-child(3),
            & p:nth-child(2) {
                text-align: center, ;
                margin: 0 auto
            }

            & p:nth-child(2) {
                margin-top: 80px;
            }
        }

        & a,
        & p {
            @include lato400;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: $font_color;
        }
    }

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        margin-bottom: 120px;

        @media (max-width:960px) {
            flex-direction: column;
            gap: 20px;
            margin-bottom: 80px;

        }

        & .card {
            & .sosial_icon {
                display: flex;
                align-items: center;
                gap: 20px;


                & li {
                    transition: .3s;

                    &:hover {
                        transform: scale(1.2);
                    }
                }
            }

            @media (max-width:960px) {
                flex: 0 1 100%;
            }
        }



        & .card:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 40px;

            & .logo {
                @include playfair400;
                font-size: 52px;
                color: $font_color;
                line-height: 24px;
                letter-spacing: 5px;
            }

            & button {
                a {
                    text-align: left;
                    color: #117451;

                }
            }

            & .sosial_icon {
                @media (max-width:960px) {
                    display: none;

                }
            }
        }

        & .card:nth-child(2) {
            & .sosial_icon {
                flex-direction: row;
                justify-content: start;
                margin-top: 40px;

                @media (min-width:960px) {
                    display: none;
                }
            }

            & ul {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 15px;

                & li {
                    display: flex;
                    gap: 15px;

                    & i {
                        padding-top: 4px;
                    }

                    & p {
                        @include lato700;
                        font-size: 16px;
                        line-height: 24px;
                        color: $font_color;
                    }

                    & a {
                        @include lato400;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: $font_color;
                        position: relative;
                    }
                }
            }

        }

        & .card:nth-child(3) {
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width:960px) {
                display: none;
            }

            & div {
                display: flex;
                align-items: start;
                gap: 30px;

                & ul {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    & li {
                        & .different_style {
                            color: #117451;
                        }

                        & a {
                            @include lato400;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            color: $font_color;
                            position: relative;

                            &::after {
                                content: "";
                                height: 1px;
                                width: 0%;
                                background: $font_gold;
                                position: absolute;
                                bottom: -5px;
                                left: 0;
                                transition: 0.3s;
                            }

                            &:hover {
                                color: $font_gold;

                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    & .whish::before {
                        content: attr(data-count);
                        position: absolute;
                        top: 50%;
                        right: -27px;
                        transform: translate(-0%, -50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #fff;
                        @include lato400;
                        font-size: 12px;
                        border-radius: 50%;
                        background-color: $font_gold;
                        width: 20px;
                        height: 20px;
                        z-index: 100;
                    }
                }
            }

            & p {
                width: 100%;
                @include lato700;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                color: $font_gold;
            }
        }
    }
}