@import './../../SCSS/vars';
@import './../../SCSS/mixins';

.btn {
    background: transparent;

    & a {
        display: flex;
        align-items: center;
        gap: 10px;
        color: $font_color;
        @include lato700;
        font-size: 16px;
        line-height: 24px;
        text-align: right;

        & svg {
            transition: 0.3s;
        }

        &:hover {
            svg {
                transform: translateX(10px);
            }
        }
    }
}