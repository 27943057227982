@import './../../SCSS/vars';
@import './../../SCSS/mixins';


.wrapper {
    width: 45%;

    @include tablet {
        width: 100%;
    }

    & .SwiperHero {
        margin-bottom: 10px;

        & .SwiperSlide {
            height: 700px;

            @include mobileM {
                height: 400px;
            }
        }
    }
}

.SwiperSlide {
    height: 230px;

    @include mobileM {
        height: 150px;
    }

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.activeSlide {
    border: 2px solid $font_gold;
}