// LightGallery 
.lg-backdrop {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background: #d8cbc579;
}

.lg-outer .lg-thumb-outer {
    background: #16141379;
}

// swiper
.swiper-slide-thumb-active {
    & img {
        transition: 0.3s;
        border: 1px solid #B59A6C;
        padding: 5px;

    }
}