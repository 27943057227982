@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';


.table_box {
    background: #FFF;
    padding: 40px 32px;

    @include oldMonitor {
        padding: 10px
    }

    @include mobileL {
        width: 100%;
    }

    & .button {
        background: $font_color;
        color: #FFF;
        margin-top: 24px;
        width: 100%;
        position: relative;


        &:hover {
            background: $font_color;
            color: #FFF;
        }

        &:hover {
            & .clue {
                opacity: 1;
                z-index: 5;
            }
        }

        & .clue {
            position: absolute;
            top: -150%;
            width: 50%;
            background: $font_color;
            padding: 12px;
            border-radius: 8px;
            font-size: 14px;
            @include lato400;
            line-height: 16px;
            opacity: 0;
            z-index: -5;
            transition: .5s;

            @include mobileL {
                width: 300px;
                top: -90px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 50%;
                bottom: -20px;
                border: 5px solid transparent;
                border: 10px solid transparent;
                border-top: 10px solid $font_color;

                @include mobileL {
                    left: 48%;
                }
            }

            @media(max-width:968px) {
                display: none;
            }
        }

        &.button_active {
            background: $font_color;

            & .clue {
                display: none;
            }

            &:Hover {
                background: #000000af;
            }
        }
    }

    & .table {
        @include mobileL {
            display: flex;
            overflow: scroll;
            padding-bottom: 40px;
        }

        box-shadow: 0px 0px 1px 0px #27323C80;


        & .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            position: relative;

            @include mobileL {
                flex-direction: column-reverse;
            }


            & .quantity_row {
                width: 146px;

                @include oldMonitor {
                    width: 120px;
                }

                @include mobileL {
                    width: 60px;
                }


            }

            & li {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 72px;
                width: 100px;
                border: 1px solid #E9EAEB;
                @include lato500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.20px;
                text-align: center;
                color: $font_color;
                transition: .3s;
                position: relative;

                @include oldMonitor {
                    width: 70px;
                    height: 40px;
                }

                @include mobileL {
                    width: 60px;
                }

                & .clue {
                    position: absolute;
                    top: -100%;
                    width: 200px;
                    background: $font_color;
                    padding: 12px;
                    border-radius: 8px;
                    font-size: 14px;
                    @include lato400;
                    line-height: 16px;
                    opacity: 0;
                    z-index: -5;
                    transition: .5s;
                    color: #FAFAFA;

                    @include mobileL {
                        width: 150px;
                        font-size: 12px;
                        padding: 5px;

                    }

                    &::after {
                        content: '';
                        position: absolute;
                        left: 45%;
                        bottom: -20px;
                        border: 5px solid transparent;
                        border: 10px solid transparent;
                        border-top: 10px solid $font_color;
                    }
                }

                & .clueIcon {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    opacity: 0;
                    cursor: help;
                    z-index: 5;

                    &:hover {
                        &~.clue {
                            opacity: 1;
                            z-index: 5;
                        }
                    }
                }

                &.cell {
                    position: relative;
                    background-position: 95% 10%;

                    &:hover {
                        border: 1px solid #B59A6C;

                        & .clueIcon {
                            opacity: 1;
                            z-index: 1;
                        }
                    }
                }

                & input {
                    height: 100%;
                    text-align: center;
                    @include lato500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.20px;
                    color: $font_color;
                    width: 100px;
                    background: transparent;
                    transition: .3s;
                    border: 2px solid transparent;

                    @include oldMonitor {
                        width: 60px;
                    }



                    &:focus {
                        border: 2px solid #924D32;
                    }
                }

                & .chooseColor {
                    width: 100%;
                    height: 100%;
                    text-align: center;

                    & svg {
                        right: 0;
                    }

                    & fieldset {
                        border: none;
                    }
                }
            }

            &.size {
                & li {
                    background: #FAF9F7;
                }

            }

            &.total {
                margin-top: 5px;

                & li {
                    @include lato700
                }

                & li:first-child {
                    width: 87%;
                    padding-left: 12px;
                    justify-content: start;
                }
            }

            & .deleteRow {
                position: absolute;
                right: 0;
                width: 20px;
                height: 20px;
                background: $font_gold;
                color: #FFF;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;

                @include mobileL {
                    width: 80%;
                    height: 30px;
                    bottom: -30px;
                    left: 0;

                }
            }
        }
    }
}

.totalQuantity {
    display: flex;

    & li:first-child {
        width: 87%;
        padding-left: 12px;
        justify-content: start;
    }

    & li {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 72px;
        width: 100px;
        border: 1px solid #E9EAEB;
        @include lato500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.20px;
        text-align: center;
        color: $font_color;

    }
}

.addRow {
    width: 146px;
    height: 72px;
    text-align: center;
    background: $font_gold;
    color: #FFF;
    font-size: 24px;
    @include lato600;
    margin-left: auto;
    display: block;
    transition: .3s;

    @include oldMonitor {
        width: 120px;
    }

    @include mobileL {
        width: 100%;
        height: 50px;
    }

    &:hover {
        background: #dbbd89;
    }
}

.colorCircle {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #e2e1e1;
    margin: 0 auto;
}