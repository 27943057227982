@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.title {
    margin: 34px 0;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 22px;
    margin-bottom: 64px;

    & .empty {
        @include lato400;
        font-size: 20px;
        color: $font_color;
    }

    @media(max-width:960px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width:768px) {
        grid-template-columns: repeat(1, 1fr);
    }
}