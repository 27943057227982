@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.card {
    flex: 0 1 23%;
    width: 100%;
    overflow: hidden;
    position: relative;
    transition: all .5s;
    height: 587px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1280px) {
        flex: 0 1 30%;
    }

    @include tablet {
        flex: 0 1 46%;
        margin-bottom: 30px;
    }

    @include mobileM {
        height: 450px;
    }

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        transition: .5s;
        background: linear-gradient(90deg, rgba(181, 154, 108, 1) 0%, rgba(181, 154, 108, 1) 35%, rgba(181, 154, 108, 1) 100%);
        top: -100%;
        left: -100%;

        @include tablet {
            content: none;
        }
    }

    &:hover::before {
        top: 0;
        left: 0;
    }

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        z-index: -1;
        position: absolute;
        transition: .5s;
        background: linear-gradient(90deg, rgba(181, 154, 108, 1) 0%, rgba(181, 154, 108, 1) 35%, rgba(181, 154, 108, 1) 100%);
        bottom: -100%;
        right: -100%;

        @include tablet {
            content: none;
        }
    }

    &:hover::after {
        bottom: 0;
        right: 0;
    }

    & .inner_link {
        display: block;
        width: 99.4%;
        height: 99.65%;

        & .inner_card {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: #fff;
            padding: 20px;
            transition: .3s;

            @include mobileL {
                width: 100%;
                height: 100%;
                padding: 0;
            }

            & .card_img {
                width: 80%;
                height: 82%;
                object-fit: cover;
                object-position: center;
                margin-bottom: 16px;
                transition: .5s;

                @include mobileL {
                    width: 100%;
                    height: 90%;
                }
            }

            &:hover .card_img {
                width: 100%;
                height: 85%;
            }

            @media (hover: none) {
                .button:hover {
                    background-color: initial;
                    /* Или любой другой стиль, чтобы убрать эффект */
                    cursor: default;
                }
            }

            & .title {
                @include playfair400;
                font-weight: 500;
                font-size: 24px;
                letter-spacing: 5px;
                color: $font_color;
                margin-bottom: 15px;
                text-transform: uppercase;
                text-align: center;

                @include mobileL {
                    font-size: 18px;
                }
            }

            & .link_btn {
                // position: absolute;
                // bottom: -50%;
                transition: .4s;
                opacity: 0;

                .btn {
                    color: $font_color;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    background-color: transparent;
                    @include lato700;
                    font-size: 16px;

                    & svg {
                        transition: 0.3s;
                    }

                    &:hover {
                        svg {
                            transform: translateX(10px);
                        }
                    }
                }

                @include mobileL {
                    display: none;
                }
            }

            &:hover .link_btn {
                // bottom: 15px;
                opacity: 1;
            }
        }
    }


}