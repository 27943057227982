@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.wrapper {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FAF9F7;

    & .container {
        width: 50%;
        padding: 64px 0 100px;
        height: 100%;
        margin: 0 auto;

        @media screen and (min-width: 1921px) {
            width: 30%;
        }


        @include old1024 {
            width: 100%
        }

        & h2 {
            text-align: center;
            margin-bottom: 16px;
        }

        & article {
            margin: 0 auto;
            @include lato400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            margin-bottom: 32px;
        }

        & form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & .errorMessage {
                @include lato400;
                font-size: 15px;
                color: red;
            }

            & .button {
                background: $font_color;
                color: #FFF;
            }

            & .upload_button {
                transition: .3s;
                background: #FFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 32px 20px;
                gap: 6px;
                border: 1px dashed #C1C1C1;

                & .addPictures {

                    z-index: -5;
                }

                & .preview_image {
                    width: 100%;
                    height: 378px;
                    object-fit: contain;
                    margin-bottom: 12px;

                }

                &:hover {
                    background-color: #e0e0e0;
                    cursor: pointer;
                }

                &.dragging {
                    background-color: #d0e0ff;
                    border-color: #0056b3;
                }


                & span:first-of-type {
                    @include lato600;
                    font-size: 14px;
                    line-height: 16px;
                    border-bottom: 1px solid $font_color;
                    color: $font_color;

                }


                & span:nth-of-type(2) {
                    @include lato400;
                    font-size: 14px;
                    line-height: 16.8px;
                    color: $font_color_trans;

                }
            }

            & .inputField:nth-child(11),
            & .inputField:nth-child(9) {
                margin-bottom: 40px;
            }

            & .inputField {
                background: #FFF;
                transition: .3s;


                & label {
                    background: transparent;
                    color: $font_color_trans;
                }

                & input {
                    border: 1px solid #EFEEEB;

                    &:focus {
                        border-bottom: 1px solid $font_gold;

                    }
                }

                & fieldset {
                    border: none
                }
            }
        }
    }
}