@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';



.section {
    background: #FAF9F7;

    @media(max-width:960px) {
        padding-top: 65px;
    }

    & .wrapper {
        max-width: 100%;
        margin-left: 5%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        @media(min-width: 1921px) {
            max-width: 1740px;
            margin-left: none;
            margin: 0 auto;
        }


        @media (max-width:960px) {
            gap: 44px;
            align-items: flex-start;
            flex-direction: column;
            margin-left: 0;
        }

        & .content_title {
            flex: 0 1 45%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            margin-bottom: 50px;
            gap: 40px;

            @media(max-width:960px) {
                flex: 0 1 100%;
                gap: 16px;
                margin: 0 5%;

            }

            & h1 {
                width: 60%;
                font-size: 70px;
                text-align: left;

                @media(max-width:1410px) {
                    font-size: 55px;
                }

                @media (max-width:960px) {
                    font-size: 36px;
                }


            }

            & p {
                width: 80%;
                @include lato400;
                font-size: 20px;
                line-height: 30px;

                @media (max-width:960px) {
                    font-size: 16px;
                    width: 100%;
                    margin-bottom: 25px;
                }
            }

            & .button {
                background: rgba(17, 116, 81, 1);
                color: #FFF;

                & svg {
                    & path {
                        fill: #FFF;
                    }
                }
            }
        }

        & .content_image {
            flex: 0 1 53%;
            display: flex;
            justify-content: space-between;
            align-items: end;
            height: 850px;

            @media(max-width:960px) {
                flex: 0 1 100%;
                width: 100%;
            }


            & img {
                width: 100%;
                height: 100%;
                object-fit: cover;

                @media(max-width:768px) {
                    height: 414px;
                }
            }
        }
    }
}