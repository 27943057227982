@import '../../../SCSS/mixins';
@import '../../../SCSS/vars';

.wrapper {
    padding: 100px 0;

    & h2 {
        margin-bottom: 65px;
    }

    & .box {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        margin-bottom: 64px;

        @media (max-width:960px) {
            justify-content: center;
            gap: 20px;

            & .card:last-child {
                display: none;
            }
        }



        & .card {
            flex: 0 1 32%;
            border: 1px solid $font_gold;
            padding: 30px 20px 70px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width:960px) {
                flex: 0 1 100%;
            }

            & img {
                width: 96px;
                height: 96px;
                margin-bottom: 12px;
                border-radius: 50%
            }

            & h3 {
                @include lato600;
                font-size: 20px;
                margin-bottom: 20px;
                color: $font_color;
                line-height: 24px;

            }

            & div {
                display: flex;
                justify-content: center;
                gap: 12px;

                & p {
                    @include lato400;
                    font-size: 18px;
                    line-height: 24px;
                    color: $font_color_trans;
                }
            }
        }
    }

    & .btn {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}