@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';

.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 100px 0;
    gap: 20px;

    @media(max-width:960px) {
        padding: 100px 0 0;
    }

    .card {
        flex: 0 1 23%;
        display: flex;
        align-items: center;
        gap: 10px;

        & svg {
            width: 50px;
            height: 50px;
        }

        @media(max-width:960px) {
            flex: 0 1 48%;
            gap: 30px;
        }

        @media(max-width:768px) {
            flex: 0 1 100%;
        }

        & div:last-child {
            & p:first-child {
                @include lato700;
                font-size: 16px;
                color: $font_color;
                margin-bottom: 2px;
                line-height: 24px;
            }

            & p:last-child {
                font-size: 16px;
                color: $font_color_trans;
                line-height: 24px;
                @include lato500;
                width: 80%;
            }
        }
    }
}