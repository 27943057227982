.wrapper {
    padding: 100px 0;

    & h2 {
        text-align: center;
        margin-bottom: 65px;
    }

    & .box {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px 22px;
        margin-bottom: 64px;

        @media(max-width:960px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width:768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    & .btn {
        text-align: center;
        display: flex;
        justify-content: center;
    }
}