@import './../../SCSS/vars';
@import './../../SCSS/mixins';

.wrapper {
    min-height: 70vh;
    background: #FAF9F7;

    & .container {
        padding: 64px 0 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;


        & h1 {
            text-align: center
        }

        & .text {
            @include lato400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: $font_color;
            width: 50%;

            @include oldMonitor {
                width: 80%;
            }

            @include mobileL {
                width: 90%;
            }
        }

        & .table_box {
            background: #FFF;
            padding: 40px 32px;

            & .button {
                margin-top: 24px;
                width: 100%;
                color: #FFF;
                background: #C9C9C9;
                padding: 12px 0;
                @include lato600;
                font-size: 18px;
                line-height: 24px;
                transition: .3s;
                position: relative;
                display: flex;
                justify-content: center;

                &:hover {
                    & .clue {
                        opacity: 1;
                        z-index: 5;
                    }
                }

                & .clue {
                    position: absolute;
                    top: -150%;
                    width: 50%;
                    background: $font_color;
                    padding: 12px;
                    border-radius: 8px;
                    font-size: 14px;
                    @include lato400;
                    line-height: 16px;
                    opacity: 0;
                    z-index: -5;
                    transition: .5s;
                }

                &.button_active {
                    background: $font_color;

                    & .clue {
                        display: none;
                    }

                    &:Hover {
                        background: #222222c7;
                    }
                }
            }
        }
    }
}

.colorCircle {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #e2e1e1;
    margin: 0 auto;
}