@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.about_us {
    & img {
        width: 100%;
    }

    & .text_wrapper {
        display: flex;
        justify-content: space-between;
        margin: 80px 0 100px;
        padding-bottom: 100px;
        border-bottom: 1px solid rgba(34, 34, 34, 0.25);

        @include mobileL {
            flex-direction: column;
        }

        & .title {
            flex: 0 1 50%;

            @include mobileL {
                margin-bottom: 20px;
            }
        }

        & .text {
            flex: 0 1 50%;
            white-space: break-spaces;
            @include lato400;
            line-height: 30px;
            font-size: 20px;
            color: $font_color;

            @include mobileL {
                font-size: 16px;
            }
        }
    }
}

.wrapper {
    border-bottom: 1px solid rgba(34, 34, 34, 0.25);
}

.feedback {
    text-align: center;
    padding: 100px 0;
    border-bottom: 1px solid rgba(34, 34, 34, 0.25);
    display: flex;
    flex-direction: column;

    & .title {
        @include playfair400;
        font-size: 48px;
        color: $font_color;
        margin-bottom: 40px;
        word-spacing: 10px;

        @include mobileL {
            font-size: 36px;
        }
    }

    &_btn {
        margin: 0 auto;
    }
}

.questions {
    & h2 {
        margin-bottom: 44px;
    }

    width: 60%;
    margin: 0 auto;
    margin-bottom: 100px;

    @include tablet {
        width: 100%;

    }

    & .faqs {
        border-top: none;
        border-bottom: 1px solid #EAECF0;

        & button {

            padding: 25px 0;
            @include lato600;

            @include mobileL {
                text-align: start;
                font-size: 16px;
            }
        }

        & p {
            color: $font_color;
        }
    }
}

.btn {
    display: flex;
    justify-content: center;
    margin: 64px auto 116px;
}