@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.reviews {
    & .bg_title {
        background-color: #FAF9F7;
        padding: 24px 0 32px;

        & .title {
            @include playfair400;
            font-size: 48px;
            color: $font_color;
            margin: 0 auto 0;
            width: 90%;

            @media screen and (min-width: 1921px) {
                max-width: 1740px;
            }
        }
    }

    & .line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #22222240;
    }

    &_wrapper {
        margin: 64px auto;

        & .card {
            margin-bottom: 40px;
            border: 1px solid rgb(181, 154, 108);
            padding: 20px;
            display: flex;
            align-items: center;
            gap: 20px;

            @include mobileL {
                flex-direction: column;
            }

            & .box_img {
                flex: 0 0 15%;
                height: 320px;

                @include oldMonitor {
                    flex: 0 0 25%;

                }

                & img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            & .content {
                flex: 1;

                & .name_date_wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    @include mobileL {
                        flex-direction: column;
                        align-items: flex-start
                    }

                    & h2 {
                        @include playfair400;
                        font-size: 24px;
                        color: $font_color;

                        @include mobileL {
                            margin-bottom: 15px;
                        }
                    }

                    & .date {
                        color: #222222E6;
                        @include lato400;
                        font-size: 18px;
                    }
                }

                & .text {
                    @include lato400;
                    font-size: 20px;
                    color: #00000080;
                    line-height: 30px;
                }

            }


        }
    }
}