@import '../../../SCSS/mixins';
@import '../../../SCSS/vars';

.wrapper {
    margin: 20px 0;

    .images_wrapper {
        display: flex;
        align-items: center;
        gap: 6px;
        @include lato700;
        font-size: 16px;
        color: $font_gold;

        & span {
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .converts {
        margin: 10px 0;

        @include mobileS {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        &_input {
            padding: 16px 20px;
            border-radius: 6px;
            box-shadow: 0px 0px 4px 1px $font_gold;
            font-size: 16px;
            @include playfair400;
            color: $font_color;
            margin-right: 20px;

            @include mobileS {
                width: 100%;
            }
        }

        & button {
            padding: 16px 20px;
            border-radius: 6px;
            font-size: 16px;
            @include playfair400;
            color: $font_color;
            background-color: $font_gold;
            color: #fff;
        }

        .converted_wrapper {
            margin-top: 20px;

            @include mobileS {
                margin-top: 0;
            }

            & .converted {
                @include lato700;
                color: $font_color;
                font-size: 20px;
            }

            & span {
                display: flex;
                gap: 5px;
                margin-top: 10px;
                @include playfair400;
                font-size: 16px;
                color: $font_gold;

                & a:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}