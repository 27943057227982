@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.wrapper {
    background: #FAF9F7;
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

    & .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        & .btn {
            background: $font_color;
            color: #FFF;
        }

        @include mobileL {
            width: 90%;
        }

        & p {
            @include lato400;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            margin-bottom: 64px;
        }

        & a {
            width: 100%;
        }
    }


}