@import '../../SCSS/mixins';
@import '../../SCSS/vars';

.catalog {
    margin: 0 0 100px;

    & .bg_title {
        background-color: #FAF9F7;
        padding: 24px 0 32px;

        & .title {
            margin: 0 auto 0;
            width: 90%;

            @media screen and (min-width: 1921px) {
                max-width: 1740px;
            }

            @include mobileL {
                font-size: 36px;
            }
        }
    }

    & .line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: #22222240;
    }

    & .wrapper {
        gap: 40px;
        padding: 30px 0 100px;
        min-height: 70vh;
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        @media(max-width:1285px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media(max-width:960px) {
            grid-template-columns: repeat(2, 1fr);
        }



        @include mobileL {
            gap: 20px 6%;

        }
    }
}