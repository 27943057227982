@import '../../../SCSS/vars';
@import '../../../SCSS/mixins';

.section {
    background: #FAF9F7;
    padding: 150px 0;

    @media(max-width:960px) {
        padding: 64px 0;
    }

    & .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width:1921px) {
            max-width: 65%;
        }

        @media(max-width:960px) {
            flex-direction: column;
            max-width: 85%;
        }

        & .img {
            object-fit: cover;
            width: 100%;
            height: 228px;
            border-radius: 120px;
        }

        & .content_title {
            flex: 0 1 40%;

            @media(max-width:960px) {
                flex: 0 1 100%;
            }

            & h2 {
                margin-bottom: 80px;

                @media(max-width:960px) {
                    text-align: start;
                    margin-bottom: 16px;

                }
            }

            & .img {
                @media(max-width:960px) {
                    display: none;
                }
            }
        }

        & .box_form {
            flex: 0 1 40%;
            padding-top: 12px;

            & .img {
                margin-top: 44px;
                height: 180px;

                @media(min-width:960px) {
                    display: none;
                }
            }

            @media(max-width:960px) {
                width: 100%;
            }

            & p {
                @include lato400;
                font-size: 20px;
                line-height: 30px;
                color: $font_color;
                margin-bottom: 32px;
            }
        }
    }
}

@media screen and (max-width: 1921px) {
    .wrapper {
        max-width: 65%;
    }
}