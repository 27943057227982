@import './../../SCSS/vars';
@import './../../SCSS/mixins';


.wrapper {
    padding: 48px 0 100px;

    & h1 {
        text-align: center;
        margin-bottom: 64px;
    }

    & div {
        text-align: left;
        white-space: break-spaces;
        @include lato400;
        font-size: 16px;
        line-height: 24px;
    }
}