@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import './SCSS/mixins';
@import './SCSS/vars';
//  font-family: "Lato", sans-serif;

// font-family: "Playfair Display", serif;
// font-optical-sizing: auto;

.container {
  max-width: 90%;
  margin: 0 auto;

  @include mobileL {
    max-width: 92%;
  }
}

.main {
  @media(max-width:960px) {
    padding-top: 100px;
  }
}

.title {
  @include playfair400;
  font-size: 48px;
  line-height: 120%;
  color: $font_color;

  @include mobileL {
    font-size: 36px;
    text-align: center;
  }
}

.button {
  padding: 16px 30px 16px 40px;
  border: 1px solid $font_color;
  color: $font_color;
  background: transparent;
  @include lato700;
  line-height: 24px;
  font-size: 16px;
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  &:disabled {
    opacity: 0.4;
    padding: 16px 40px;

    & svg {
      display: none;
    }
  }

  &:hover {
    padding-right: 40px;

    & svg {
      visibility: visible;
      opacity: 1;
      width: 25px;
      transform: translateX(0px);
    }
  }

  & svg {
    visibility: hidden;
    opacity: 0;
    transition: .3s;
    width: 0;
    transform: translateX(-20px);
  }

  &:disabled:hover {
    background-color: transparent;
    color: $font_color;
    cursor: not-allowed;
  }

  @media(max-width:960px) {
    padding: 16px 40px;

    & svg {
      display: none;
    }
  }

  @media (max-width:768px) {
    font-size: 14px;
  }
}







main {
  min-height: 70vh;
}

@media screen and (min-width: 1921px) {
  .container {
    max-width: 1740px;
  }
}

.wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &_under {
    text-align: center;
    @include playfair400;

    & h1 {
      font-size: 40px;
      margin-bottom: 20px;
      color: $font_color;
    }

    & h2 {
      font-size: 25px;
      text-decoration: underline;
      color: $font_gold;
    }
  }
}

.error {
  @include playfair400;
  font-size: 16px;
  color: rgb(162, 19, 19);
}