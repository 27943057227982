@import './../../SCSS/vars';
@import './../../SCSS/mixins';


.accordion_item {
    border-top: 1px solid $font_color_trans;
    border-bottom: 1px solid $font_color_trans;
    display: flex;
    flex-direction: column;
    justify-content: center;

    & .accordion_header {
        padding: 15px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: transparent;
        font-size: 20px;
        line-height: 30px;
        @include lato400;
        color: $font_color;
    }

    & .accordion_collapse {
        height: 0;
        overflow: hidden;
        transition: height 0.3s ease, margin-bottom 0.3s ease;

    }

    & .accordion_body {
        --animate-duration: 0.6s;
        @include lato400;
        color: $font_color_trans;
        font-size: 16px;
        line-height: 24px;
        white-space: break-spaces;
    }

}